exports.products = [
  {
    title: "Air Bar Max",
    imgs: [
      "products/airbar_max_vape_1.png",
    ],
    type: "disposables",
    category: "vape",
    flavors: [
      "shake shake",
      "berries shake",
      "sakura grape",
      "guave shake",
      "guava ice",
      "berry lemon shake",
      "apple shake",
      "berries shake",
      "banana shake"
    ]
  },
  {
    title: "Air Bar Mini",
    imgs: [
      "products/airbar_mini_vape_1.png",
    ],
    type: "disposables",
    category: "vape",
    flavors: [
      "strawberry kiwi",
      "raspberry grapefruit",
      "watermelon ice",
      "carmel popcorn",
      "banana ice",
      "blueberry ice",
      "raspberry grapefruit",
      "aloe black currant",
      "mix berries",
      "apple shake",
      "triple shake",
      "raspberry watermelon",
      "guava shake",
      "shake shake",
      "strawberry watermelon",
      "orange shake",
      "orange juice",
      "banana shake",
      "vitamin water",
      "pineapple shake",
      "cranberry lemonade ice",
      "strawberry mango"
    ]
  },
  {
    title: "Cali Flow",
    imgs: ["products/califlow_vape_1.png"],
    type: "disposables",
    category: "vape",
    flavors: [
      "mixed berries",
      "apple ice",
      "mamba",
      "blueberry ice",
      "blue razz",
      "blue razz ice",
      "watermelon ice",
      "grape ice",
      "mango ice",
      "peach ice",
      "pink lemonade",
      "guava ice"
    ]
  },
  {
    title: "Huge",
    imgs: ["products/huge_vape_1.png"],
    type: "disposables",
    category: "vape",
    flavors: [
      "cotton candy",
      "rainbow candy",
      "strawberry watermelon",
      "lush berries",
      "banana ice",
      "strawberry banana"
    ]
  },
  {
    title: "Hyde",
    imgs: ["products/hyde_vape_1.png", "products/hyde_vape_2.png"],
    type: "disposables",
    category: "vape",
    flavors: [
      "neon rain",
      "aloe grape",
      "lush ice",
      "pineapple ice",
      "strawberries and cream"
    ]
  },
  {
    title: "Hyde Plus",
    imgs: ["products/hyde_vape_2.png"],
    type: "disposables",
    category: "vape",
    flavors: [
      "pineapple ice",
      "cherry lemonade",
      "banana ice",
      "raspberry watermelon",
      "honey dew punch",
      "aloe grape",
      "pineapple peach mango"
    ]
  },
  {
    title: "Luto",
    imgs: ["products/luto_vape_1.png"],
    type: "disposables",
    category: "vape",
    flavors: [
      "mango ice",
      "cool mint",
      "cola cream",
      "cherry cola",
      "watermelon ice",
      "wild berry ice",
      "banana ice cream",
      "strawberry donut",
      "red bull ice",
      "red apple ice",
      "mango ice cream"
    ]
  },
  {
    title: "Zenith",
    imgs: ["products/zenith_ejuice_1.png"],
    type: "e-juice",
    category: "vape",
  },
  {
    title: "Flair",
    imgs: [
      "products/flair_vape_1.png",
      "products/flair_vape_2.png",
      "products/flair_vape_3.png",
    ],
    type: "disposables",
    category: "vape",
    flavors: ['cool mint','cola ice','mango peach pineapple','blue razz','lush ice','hawaiian breeze','ice orange cream','strawberry banana','brain squeeze']
  },
  {
    title: "Fume",
    imgs: ["products/fume_vape_1.png"],
    type: "disposables",
    category: "vape",
    flavors: [
      "peach on cream",
      "strawberry mango",
      "strawberry lemonade",
      "strawberry watermelon",
      "mango",
      "melon ice",
      "banana ice",
      "strawberry",
      "strawberry cheesecake",
      "grape",
      "red bull"
    ]
  },
  {
    title: "Mega",
    imgs: ["products/mega_vape_1.png"],
    type: "disposables",
    category: "vape",
    flavors: [
      "clear",
      "cool mint",
      "frozen blue razz",
      "red bull",
      "frozen peach",
      "mango ice cream",
      "tropical lemonade",
      "frozen tangerine",
      "frozen mango"
    ]
  },
  {
    title: "3 CHI",
    imgs: ["products/3chi_vape_1.png"],
    type: "cartridge",
    price: 35,
    category: "delta8",
    flavors: [
      "cali-O",
      "pancakes",
      "snowman",
      "incredible hulk"
    ]
  },
  {
    title: "BLZD",
    imgs: ["products/blzd_vape_1.png"],
    type: "cartridge",
    category: "delta8",
  },
  {
    title: "Kaliboom",
    imgs: ["products/kaliboom_cartridge_1.png"],
    type: "disposables",
    category: "delta8",
  },
  {
    title: "Beastbar",
    imgs: ["products/beastbar_vape_1.png"],
    type: "disposables",
    category: "delta8",
  },
  {
    title: "Full Send",
    imgs: ["products/fullsend_edible_1.png"],
    type: "edible",
    category: "delta8",
    flavors: [
      'watermelon','passion fruit','blue razz','green apple','pineapple'
    ]
  },
  {
    title: "3 CHI",
    imgs: [
      "products/3chi_edible_1.png",
      "products/3chi_edible_2.png",
    ],
    type: "edible",
    category: "delta8",
  },
  {
    title: "Area 52",
    imgs: [
      "products/area52_edible_1.png",
    ],
    type: "edible",
    category: "delta8",
  },
  {
    title: "Miscellaneous",
    imgs: [
      "products/edible_1.png",
      "products/edible_2.png",
      "products/edible_3.png",
    ],
    type: "edible",
    category: "delta8",
  },
  {
    title: "Hempbrand",
    imgs: ["products/hempbrand_flower_1.png"],
    type: "flower",
    category: "delta8",
  },
  {
    title: "Packwoods",
    imgs: ["products/packwoods_flower_1.png"],
    type: "flower",
    category: "delta8",
  },
  {
    title: "Cloud Nurdz",
    imgs: ["products/cloud_nurdz_ejuice_1.png"],
    type: "e-juice",
    category: "vape",
    flavors: [
      "watermelon apple",
      "peach blueberry raspberry",
      "grape apple",
      "grape strawberry",
      "kiwi melon"
    ]
  },
  {
    title: "Keep it 100",
    imgs: ["products/keep_it_100_ejuice_1.png"],
    type: "e-juice",
    category: "vape",
    flavors: [
      "og blue",
      "shake",
      "og crunch",
      "maui blast",
      "og island fusion",
      "og summer blue",
      "og blue iced",
      "og tropical blue"
    ]
  },
  {
    title: "Naked",
    imgs: ["products/naked_ejuice_1.png"],
    type: "e-juice",
    category: "vape",
    flavors: [
      "mango",
      "straw lime",
      "all melon",
      "melon",
      "hawaiian pog",
      "pineapple berry",
      "lava flow",
      "apple",
      "berry",
      "euro gold",
      "strawberry",
      "banana",
      "melon kiwi",
      "really berry"
    ]
  },
  {
    title: "Smok",
    imgs: [
      "products/smok_vape_1.png",
      "products/smok_vape_2.png",
      "products/smok_vape_3.png",
      "products/smok_vape_4.png"
    ],
    type: "box mod",
    category: "vape",
    flavors: [
      "ipx 80 kit",
      "scar 18 kit"
    ]
  },
];
