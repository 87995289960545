import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
  },
  {
    path: '/inventory/:category',
    name: 'Inventory',
    component: () => import(/* webpackChunkName: "inventory" */ '@/views/Inventory.vue'),
    props: true,
  },
  {
    path: '/store/hookah',
    name: 'Hookah',
    component: () => import(/* webpackChunkName: "hookah" */ '@/views/Hookah.vue'),
    props: true
  },
  {
    path: '/store/smoke',
    name: 'Smoke',
    component: () => import(/* webpackChunkName: "smoke shop" */ '@/views/SmokeShop.vue'),
    props: true
  },
  {
    path: '/products/:name/:type',
    name: 'Product',
    component: () => import(/* webpackChunkName: "product" */ '@/views/Product.vue'),
    props: true
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '@/components/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
