<template>
  <v-row justify="center">
    <v-dialog v-model="ageVerification" persistent max-width="400">
      <v-card dark>
        <v-card-title class="display-2"> Welcome </v-card-title>
        <v-card-text>Please verify your age to continue.</v-card-text>
        <v-card-text>

            <v-row justify="center">
                <v-col cols="12">
                    <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        v-model="date"
                        label="Date of Birth"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="date"
                        color="purple"
                        :active-picker.sync="activePicker"
                        :max="
                        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                            .toISOString()
                            .substr(0, 10)
                        "
                        min="1950-01-01"
                        @change="save"
                    ></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="verifyAge(date)"> verify </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data: () => ({
    activePicker: null,
    date: null,
    menu: false,
  }),
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
    },
    ...mapActions(['verifyAge']),
  },
  computed: mapGetters(['ageVerification'])
};
</script>

<style>
</style>