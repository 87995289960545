import Vue from "vue";
import Vuex from "vuex";
import { products } from "@/services";
Vue.use(Vuex);
import moment from 'moment'
import createPersistedState from "vuex-persistedstate";

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

export default new Vuex.Store({
  state: {
    delta_8: [],
    vape: [],
    isMinor: true,
    current_product: null
  },
  plugins: [createPersistedState()],
  getters: {
    delta8: (state) => state.delta_8,
    vape: (state) => state.vape,
    trending: (state) => [
      state.delta_8[getRandomInt(state.delta_8.length)],
      state.vape[getRandomInt(state.vape.length)],
      state.delta_8[getRandomInt(state.delta_8.length)+1],
      state.vape[getRandomInt(state.vape.length)-1],
    ],
    current: (state) => state.current_product,
    ageVerification: (state) => state.isMinor
  },
  mutations: {
    SET_DELTA8_PRODUCTS(state, payload) { state.delta_8 = payload },
    SET_VAPE_PRODUCTS(state, payload) { state.vape = payload },
    SET_CURRENT_PRODUCT(state, payload) { state.current_product = payload },
    SET_AGE_VERIFICATION(state, payload) { state.isMinor = payload }
  },
  actions: {
    loadProducts({ commit }) {
      commit("SET_DELTA8_PRODUCTS", products.filter((x) => { return x.category == 'delta8'}))
      commit("SET_VAPE_PRODUCTS", products.filter((x) => { return x.category == 'vape'}))
    },
    getProduct({commit}, payload){       
      let { title, type } = payload
      let filtered = products.filter(function (el) {
        if (el.title == title && el.type == type) {
          return true;
        }
      });
      commit("SET_CURRENT_PRODUCT", filtered[0]);
    },
    verifyAge({commit}, payload) {
      let age = Math.abs(moment(payload, 'YYYY').diff(age, 'years'))
      if( age >= 21)
        commit('SET_AGE_VERIFICATION', false)
      else  
        window.alert('You must be 21 years or older to browse this website.')
    }
  },
  modules: {},
});
