<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" class="purple darken-2" relative dark app>
      <v-list nav dense>
        <v-list-item to="/">
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>
        <v-list-item to="/inventory/delta8">
          <v-list-item-title>Delta 8</v-list-item-title>
        </v-list-item>
        <v-list-item to="/store/hookah">
          <v-list-item-title>Hookah</v-list-item-title>
        </v-list-item>
        <v-list-item to="/inventory/vape">
          <v-list-item-title>Vape Shop</v-list-item-title>
        </v-list-item>
        <v-list-item to="/store/smoke">
          <v-list-item-title>Smoke Shop</v-list-item-title></v-list-item
        >
      </v-list>
    </v-navigation-drawer>

    <v-app-bar dense dark app>
      <v-app-bar-nav-icon
        v-if="isMobile"
        @click.stop="drawer = !drawer"
        dark
      ></v-app-bar-nav-icon>
      <v-tabs v-if="!isMobile" dark>
        <v-tabs-slider color="purple accent-3"></v-tabs-slider>
        <v-tab to="/">Home</v-tab>
        <v-tab to="/inventory/delta8">Delta 8</v-tab>
        <v-tab to="/store/hookah">Hookah</v-tab>
        <v-tab to="/inventory/vape">Vape Shop</v-tab>
        <v-tab to="/store/smoke">Smoke Shop</v-tab>
      </v-tabs>
    </v-app-bar>
    <v-main>
      <v-container class="grey lighten-4" fluid>
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer padless>
      <v-card flat tile width="100%" dark class="text-center">
        <v-card-text>
          <v-btn href="https://instagram.com/a1smoke_shop" class="mx-4" icon>
            <v-icon size="24px">mdi-instagram</v-icon>
          </v-btn>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} — <strong>A1 Smoke Shop</strong>
        </v-card-text>
        <v-card-text class="text-secondary"
          >Developed with
          <span class="mx-2"
            ><v-icon color="red darken-2">mdi-heart</v-icon></span
          >
          <a
            class="white--text font-weight-medium text-decoration-none"
            href="https://conceptcodes.dev"
            >by ConceptCodes</a
          ></v-card-text
        >
      </v-card>
    </v-footer>
    <dob-modal></dob-modal>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import DobModal from "@/components/DobModal.vue";

export default {
  name: "App",
  data: () => ({
    drawer: false,
  }),
  created() {
    this.loadProducts();
  },
  components: {
    "dob-modal": DobModal,
  },
  methods: {
    ...mapActions(["loadProducts"]),
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
};
</script>
